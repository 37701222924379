import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ScrollBtn from "../components/scroll-btn"
import ReactMarkdown from "react-markdown"
import Header from "../components/header"
import Footer from "../components/footer"

import { graphql } from "gatsby"
import { useEffect } from "react"
import { isBrowser } from "../utils/isBrowser"
import { scrollToSection } from "../utils/scrollToSection"

const ServicesPage = props => {
  const {
    Title,
    IntroText,
    OurMissionLeft,
    OurMissionRight,
    Service,
    InquiryNoticeHTML,
    Technologies,
    locale,
  } = props.data.strapiServicePage

  useEffect(() => {
    if (isBrowser()) {
      const sections = document.querySelectorAll(
        "#gatsby-focus-wrapper section"
      )
      let currentSection = 0

      window.addEventListener("keyup", initScroller, true)

      function initScroller(event) {
        event.preventDefault()
        event.stopPropagation()

        if (event.code === "ArrowDown") {
          if (currentSection < sections.length - 1)
            currentSection = currentSection + 1

          scrollToSection(sections, currentSection)
        }
        if (event.code === "ArrowUp") {
          if (currentSection > 0) currentSection = currentSection - 1

          scrollToSection(sections, currentSection)
        }
      }

      return function cleanup() {
        window.removeEventListener("keyup", initScroller, true)
      }
    }
  })

  return (
    <>
      <Header
        siteTitle={props.data.site.siteMetadata?.title || `Title`}
        data={props.data.allStrapiHeader.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
        localizations={
          props.data.strapiServicePage.localizations.data[0].attributes
        }
        contactSlug={props.data.strapiContact.slug}
        currentPage={props.data.strapiServicePage.slug}
        locale={locale}
      />
      <Layout>
        <Seo
          lang={locale}
          description={IntroText.data.IntroText}
          title={Title}
        />
        <section className="single-page__hero flex flex-col justify-center lg:min-h-[720px] px-6 relative">
          <div className="text-center my-8">
            <div>
              <div className="hero-circles-container">
                <div className="circle circle--dashed circle--1 circle--1-dashed"></div>
                <div className="circle circle--dashed circle--2 circle--2-dashed"></div>
                <div className="circle circle--dashed circle--3 circle--3-dashed"></div>
                <div className="circle circle--dashed circle--4 circle--4-dashed"></div>
              </div>
            </div>
            <h1 className="single-page__hero-title">{Title}</h1>

            <div className="single-page__hero-text">
              <ReactMarkdown>{IntroText.data.IntroText}</ReactMarkdown>
            </div>
          </div>
          <div className="scrollButtonContainer">
            <ScrollBtn
              beforeText={
                props.data.strapiGeneralTranslation.ScrollDownOrJustUse
              }
              afterText={props.data.strapiGeneralTranslation.Keys}
            />
          </div>
        </section>
        <section className="our-mission-section">
          <div className="our-mission-section__column">
            <ReactMarkdown>{OurMissionLeft.data.OurMissionLeft}</ReactMarkdown>
          </div>
          <div className="our-mission-section__column">
            <ReactMarkdown>
              {OurMissionRight.data.OurMissionRight}
            </ReactMarkdown>
          </div>
        </section>
        <div className="services-section">
          <div className="services">
            {Service.map((service, index) => {
              return (
                <section
                  key={index}
                  id={service.Title.toLowerCase().split(" ").join("-")}
                  className="service"
                >
                  <span className="service__number">{index + 1}</span>
                  <h2 className="service__title">{service.Title}</h2>
                  <div className="service__content">
                    <ul className="service__list">
                      {service.FeatureListItem.map((item, index) => {
                        return (
                          <li key={index} className="service__list-item">
                            {item.Text}
                          </li>
                        )
                      })}
                    </ul>
                    <div className="service__text">
                      <ReactMarkdown>{service.Text.data.Text}</ReactMarkdown>
                    </div>
                  </div>
                </section>
              )
            })}
          </div>
        </div>
        <section className="inquery-section">
          <div className="inquery">
            <div className="inquery__text">
              <div
                dangerouslySetInnerHTML={{
                  __html: InquiryNoticeHTML.data.InquiryNoticeHTML,
                }}
              ></div>
            </div>
          </div>
        </section>

        <section className="tech-wrapper py-12 lg:py-40 px-6 lg:px-0 container max-w-project lg:flex lg:flex-row justify-between">
          <div className="max-w-[535px]">
            <h1 className="mb-7">{Technologies.Heading}</h1>
          </div>
          <div className=" max-w-[780px] tech-images">
            {Technologies.Image.map((image, index) => {
              return (
                <img
                  key={index}
                  height={"60"}
                  src={image.TechImage.url}
                  alt={image.TechImage.alternativeText}
                  loading="lazy"
                />
              )
            })}
          </div>
        </section>
      </Layout>
      <Footer
        contactSlug={props.data.strapiContact.slug}
        locale={locale}
        socialLinks={props.data.strapiGeneralSetting.SocialLinks}
        data={props.data.allStrapiFooter.nodes.find(
          header => header.locale === props.pageContext.locale
        )}
      />
    </>
  )
}

export const query = graphql`
  query ($slug: String, $locale: String) {
    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: $locale }) {
      slug
    }

    strapiServicePage(slug: { eq: $slug }, locale: { eq: $locale }) {
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      Title
      IntroText {
        data {
          IntroText
        }
      }
      OurMissionLeft {
        data {
          OurMissionLeft
        }
      }
      OurMissionRight {
        data {
          OurMissionRight
        }
      }
      Service {
        FeatureListItem {
          Text
        }
        Text {
          data {
            Text
          }
        }
        Title
      }
      InquiryNoticeHTML {
        data {
          InquiryNoticeHTML
        }
      }
      Technologies {
        Heading
        Image {
          TechImage {
            url
          }
        }
      }
    }

    allStrapiFooter {
      nodes {
        locale
        ContactText
        Heading
        RightsClaim
        SocialHeading
        Text
        Secondary {
          Link
          Text
        }
        Location {
          Heading
          Text {
            data {
              Text
            }
          }
        }
        Footer {
          Link
          Text
        }
      }
    }

    allStrapiHeader {
      nodes {
        locale
        MobileContactButtonText
        MobileContactHeading
        MobileContactText
        Links {
          Link
          Text
        }
        ContactText
      }
    }
    strapiGeneralSetting(locale: { eq: $locale }) {
      ContactSection {
        Headline
        TextPlainHTML {
          data {
            TextPlainHTML
          }
        }
      }
      SocialLinks {
        Facebook
        LinkedIn
        Twitter
      }
      SocialShareLinks {
        Facebook
        LinkedIn
        Twitter
      }
    }

    strapiGeneralTranslation(locale: { eq: $locale }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
    }
  }
`

export default ServicesPage
